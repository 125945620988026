@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&display=swap');


html {
  scroll-behavior: smooth;
}

body {
  background-color: #faf7f4;
}

div.nav-box{
  height: 100vh;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav {
  height: 300px;
  width: 40px;
  display: inline-block;
  position: fixed;
}

nav a {
  text-decoration: none;
  color: #111;
}

nav > a > button {
  border: none;
  background: none;
  margin: 3px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
    cursor: pointer;

}

nav div.tick {
  margin: 5px;
  width: 12px;
  height: 4px;
  background-color: black;
}


nav div.label {
  text-transform: capitalize;
  font-size: 13pt;
  font-weight: 600;
  color: #000;
  margin-left: 10px;
  font-family: 'Crimson Text', serif;
  transform: translateY(-2px);
}

nav > a > div:hover {
  font-weight: 500;
  color: #333;

}
