@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');


.listpage {
  margin-top: 100px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Open Sans', sans-serif;

}

.listpage a {
  text-decoration: none;
  color: #222;
}

.listitem {
  max-width: 600px;
  width: 70vw;
  margin: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ADADAD;
}

.listitem:hover {
  opacity: .5;
}

.listtitle {
  font-size: 14pt;
  font-weight: 700;

}

.listauthor {
  margin: 10px 0px;
  font-size: 12pt;
  font-weight: 500;
}

.listdate {
  margin: 10px 0px;
  font-size: 15pt;
  font-weight: 400;
}
