
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

main {
  width: 100vw;
  display: flex;
  justify-content: flex-start;
}

.button{
  position: absolute;
  right: 100px;
  width: 100px;
  height: 20px;
}

.content {
  max-width: 700px;
  padding-right: 60px;
  margin: 100px 0px 0px 220px;
  font-family: 'Source Serif Pro', serif;
  color: #222;

}


.title > div {
  padding-bottom: 10px;
}


.title {
  font-family: 'Source Sans Pro', sans-serif;

}


.titlename {
  font-size: 20pt;
  font-weight: 600;
  color: #111;

}


.author {
  font-size: 17pt;

  font-weight: 500;
  color: #222;

}


.date {
  font-size: 14pt;
  font-weight: 400;
  color: #222;

}

h3 {
  font-weight: 600;
  font-size: 18pt;
  color: #222;
  font-family: 'Source Sans Pro', sans-serif;
  margin: 80px 0px 0px 0px;
}

h4 {
  font-weight: 600;
  font-size: 14pt;
  color: #222;
  font-family: 'Source Sans Pro', sans-serif;
}

p {
  font-size: 14pt;
  letter-spacing: .2px;
  line-height: 1.5em;
}

.study li {

  margin: 15px 0px;
  font-size: 14pt;

}

#clause p {
  text-align: center;
}

#transition {
  margin-top: 120px;

}

#transition p{
  font-size: 12pt;
}

img.media {
  margin: 20px 0px;
  box-shadow: 2px 2px 8px #ADADAD;
  max-width: 600px;
}
